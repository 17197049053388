import { Write } from "@gimlite/watermelon"


export default (t: (value: string) => string) => {
  return <div
    style={{
      paddingLeft: '20px',
      paddingTop: '20px',
    }}
  >
    <Write
      data={{ item: t('tenant-portal-title') }}
      config={{
        mode: 'title-large',
        wrap: true,
        color: 'primary'
      }}
    />
  </div>
}
