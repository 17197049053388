import {
  Button,
  Col,
  Empty,
  Form,
  Header,
  Icon,
  Input,
  Loading,
  Space,
  Widget,
  Write,
  Zone,
  digitRule,
  lowercaseLetterRule,
  minLengthPasswordRule,
  notifService,
  requestGQL,
  specialCharRule,
  uppercaseLetterRule,
  useMyUrl,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';

import {
  PoolUser,
  VerifyAndUpdateUser,
  VerifyAndUpdateUserResponse,
  VerifyUser,
  VerifyUserResponse,
  verifyAndUpdateUserGQL,
  verifyUserGQL,
} from '@gimlite/osp';
import { dispatch } from '@gimlite/router';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import OspFull from '../../../assets/logos/osp_full';
import './motorist-confirm.scss';

export const MotoristConfirm = observer(() => {
  const { t, lang } = useTranslation();

  const { getParamsUrl } = useMyUrl();
  const { GlobalStore } = useRootStore();

  const [redirect, setRedirect] = useState<boolean>(false);
  const [inLoad, setInLoad] = useState<boolean>(true);
  const [error, setError] = useState<'LINK' | 'TOKEN'>();
  const [passwordValidFormat, setPasswordValidForm] = useState(false);
  const [passwordValidSame, setPasswordValidSame] = useState(false);
  const [passwordValues, setPasswordValues] = useState<Object>({});
  const [poolUser, setPoolUser] = useState<PoolUser>();

  const verifyUser = useCallback(async (token: string) => {
    try {
      const verifyToken = (await requestGQL({
        params: {
          token,
        } as VerifyUser,
        gql: verifyUserGQL,
      })) as VerifyUserResponse;

      setPoolUser({ ...verifyToken.user });
      setRedirect(() => false);
      setError(() => undefined);
    } catch (error: any) {
      if (error?.message === 'MSAF_4') {
        setError(() => undefined);
        setRedirect(() => true);
      } else {
        setError(() => 'TOKEN');
      }
    } finally {
      setInLoad(() => false);
    }
  }, []);

  const verifyAndUpdateUser = useCallback(
    async ({ token, user }: VerifyAndUpdateUser['input']) => {
      try {
        const response = (await requestGQL({
          params: {
            input: {
              token,
              user: { ...user, username: poolUser?.username },
            },
          } as VerifyAndUpdateUser,
          gql: verifyAndUpdateUserGQL,
        })) as VerifyAndUpdateUserResponse;

        notifService.send('NOTIF', {
          mode: 'success',
          content: t('thePasswordHasBeenRegistered'),
        });
      } catch (error) {
        notifService.send('NOTIF', {
          mode: 'error',
          content: t('thePasswordCouldNotBeRegistered'),
        });
      } finally {
        verifyUser(token);
      }
    },
    [getParamsUrl, poolUser],
  );

  useEffect(() => {
    if (getParamsUrl.token) {
      verifyUser(getParamsUrl.token);
    } else {
      setError(() => 'LINK');
      setInLoad(() => false);
    }
  }, [getParamsUrl]);

  useEffect(() => {
    dispatch('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-operators-global-v2' },
    });
  }, []);

  return (
    <div className="motoristConfirm">
      <Header config={{ logo: OspFull() }}></Header>
      {inLoad ? (
        <div className="motoristConfirm__load">
          <Loading></Loading>
          <Write
            className="motoristConfirm__load__text"
            data={{ item: t('waitingForServerResponse') }}
            config={{ mode: 'value-medium' }}
          ></Write>
        </div>
      ) : error ? (
        <div className="motoristConfirm__error">
          <Icon
            config={{
              type: 'faCircleExclamationSolid',
              color: 'error',
              size: 'giga',
            }}
          ></Icon>
          <Write
            className="motoristConfirm__error__text"
            data={{
              item: error === 'LINK' ? t('fakeLink') : t('fakeToken'),
            }}
            config={{ mode: 'value-large', color: 'error' }}
          ></Write>
        </div>
      ) : redirect ? (
        <div className="motoristConfirm__redirect">
          <div
            onClick={() => {
              window.location.replace('/');
            }}
            className="motoristConfirm__redirect__contain"
          >
            <Write
              className="motoristConfirm__redirect__contain__text"
              data={{
                item: t('goToPoolUserPortal'),
              }}
              config={{ mode: 'value-large', color: 'black' }}
            ></Write>
            <Icon
              config={{
                type: 'faArrowRightSolid',
                color: 'black',
                size: 'medium',
              }}
            ></Icon>
          </div>
        </div>
      ) : (
        <Zone
          className="motoristConfirm__contain"
          config={{
            zones: [
              ['identity', 'auth'],
              ['identity', 'empty'],
            ],
            rows: ['min-content'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'identity',
            }}
          >
            <Widget config={{ title: t('confirmYourIdentity') }}>
              <Empty config={{ mode: { name: 'commingSoon' } }}></Empty>
            </Widget>
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'empty',
            }}
          >
            <></>
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'auth',
            }}
          >
            <Widget config={{ title: t('authentication') }}>
              <Form
                config={{
                  validateOnChange: true,
                }}
                data={{
                  value: passwordValues,
                }}
                handleEvent={{
                  submit: (value) => {
                    verifyAndUpdateUser({
                      user: {
                        password: value.confirmNewPassword,
                      },
                      token: getParamsUrl.token,
                    });
                  },
                  validate: (isValid) => {
                    setPasswordValidForm(() => isValid);
                  },
                  change: (values) => {
                    setPasswordValues(() => values);
                    setPasswordValidSame(
                      () => values.newPassword === values.confirmNewPassword,
                    );
                  },
                }}
              >
                <Form.Item
                  config={{
                    name: 'newPassword',
                    label: t('newPassword'),
                    way: 'vertical',
                    rules: [
                      { required: true },
                      {
                        validator: minLengthPasswordRule,
                      },
                      {
                        validator: uppercaseLetterRule,
                      },
                      {
                        validator: lowercaseLetterRule,
                      },
                      {
                        validator: digitRule,
                      },
                      {
                        validator: specialCharRule,
                      },
                    ],
                  }}
                >
                  <Input config={{ type: { name: 'password' } }}></Input>
                </Form.Item>
                <Space config={{ way: 'vertical', count: 1 }}></Space>
                <Form.Item
                  config={{
                    name: 'confirmNewPassword',
                    label: t('confirmNewPassword'),
                    way: 'vertical',
                    rules: [
                      { required: true },
                      {
                        validator: minLengthPasswordRule,
                      },
                      {
                        validator: uppercaseLetterRule,
                      },
                      {
                        validator: lowercaseLetterRule,
                      },
                      {
                        validator: digitRule,
                      },
                      {
                        validator: specialCharRule,
                      },
                    ],
                  }}
                >
                  <Input config={{ type: { name: 'password' } }}></Input>
                </Form.Item>
                <Space config={{ way: 'vertical', count: 2 }}></Space>
                <Button
                  config={{
                    text: t('registerPassword'),
                    type: { value: 'submit' },
                    disabled: !(passwordValidFormat && passwordValidSame),
                  }}
                ></Button>
                <Space config={{ way: 'vertical', count: 2 }} />

                {passwordValidFormat && !passwordValidSame && (
                  <Col
                    config={{
                      width: 'full',
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                  >
                    <Write
                      data={{ item: t('passwordsAreDifferent') }}
                      config={{ color: 'error', mode: 'key-small-light' }}
                    />
                  </Col>
                )}
              </Form>
            </Widget>
          </Zone.Area>
        </Zone>
      )}
    </div>
  );
});
