import { ContractDetails, ContractList } from '@gimlite/osp/';
import {
  Page,
  Zone,

  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import { useParams } from 'react-router-dom';

export const ContractPage = () => {
  const { parkingId, contractId } = useParams();
  const { getParamsUrl } = useMyUrl({});
  const { t } = useTranslation();

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list', 'details']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'list' }}>
            <ContractList
              widget={{ title: t('listOfContracts') }}
              parkingId={parkingId}
              selectedId={contractId}
              clicked={getParamsUrl.clicked}
            />
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            <ContractDetails contractId={contractId} parkingId={parkingId} />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
};
